import React, { createContext, useContext, useReducer } from "react";

//prepares the Data layer
export const StateContext = createContext();

//Wrap the app and provide data layer to all components of app
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

//pull information from data layer
export const useStateValue = () => useContext(StateContext);
